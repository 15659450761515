import { loader } from 'graphql.macro'

export const GET_TP_CACHE_DATA = loader('./getTPCacheData.gql')
export const LOGIN = loader('./login.gql')
// App
export const APP_DATA = loader('./app/appData.gql')

// Autobot
export const CREATE_AUTOBOT = loader('./autobots/create.gql')
export const DELETE_AUTOBOT = loader('./autobots/delete.gql')
export const LIST_AUTOBOTS = loader('./autobots/list.gql')
export const UPDATE_AUTOBOT = loader('./autobots/update.gql')

// CopayRequest
export const ADD_COPAY_INSURANCE = loader('./copayRequests/addCopayInsurance.gql')
export const ADD_COPAY_INSURANCE_OPTION = loader('./copayRequests/addCopayInsuranceOption.gql')
export const CLEAR_COPAY_LOCK = loader('./copayRequests/clearLock.gql')
export const COMPLETE_COPAY_REQUEST_FILL = loader('./copayRequests/completeCopayRequestFill.gql')
export const CREATE_COPAY_REQUEST = loader('./copayRequests/create.gql')
export const GET_BASIC_COPAY = loader('./copayRequests/getBasicCopay.gql')
export const GET_COPAY_BY_CORE_COPAY_TOKEN = loader('./copayRequests/getCopayByCoreCopayToken.gql')
export const GET_COPAYS_BY_STATUS_COUNT = loader('./copayRequests/getCopayCountByStatus.gql')
export const GET_COPAY_COUNT = loader('./copayRequests/getCopayCount.gql')
export const GET_COPAY_LOCK = loader('./copayRequests/getLock.gql')
export const GET_PRESCRIPTION_COPAYS = loader('./copayRequests/getPrescriptionCopays.gql')
export const LIST_COPAYS = loader('./copayRequests/list.gql')
export const LIST_FULFILLMENT_COPAYS = loader('./copayRequests/listFulfillment.gql')
export const REMOVE_COPAY_INSURANCE = loader('./copayRequests/removeCopayInsurance.gql')
export const REROUTE_COPAY = loader('./copayRequests/reroute.gql')
export const RESOLVE_COPAY_TRIAGE = loader('./copayRequests/resolveTriage.gql')
export const REJECT_COPAY_REQUEST = loader('./copayRequests/rejectCopayRequest.gql')
export const REJECT_COPAY_REQUEST_FILL = loader('./copayRequests/rejectCopayRequestFill.gql')
export const SEARCH_COPAY_TRIAGE_REASONS = loader('./copayRequests/searchTriageReasons.gql')
export const SEARCH_COPAY_MEDICATIONS = loader('./copayRequests/searchMedications.gql')
export const SET_COPAY_IN_TRIAGE = loader('./copayRequests/setCopayInTriage.gql')
export const SET_COPAY_INSURANCE = loader('./copayRequests/setCopayInsurance.gql')
export const SET_COPAY_LOCK = loader('./copayRequests/setLock.gql')
export const UPDATE_COPAY_INSURANCE = loader('./copayRequests/updateCopayInsurance.gql')
export const UPDATE_COPAY_REQUEST_FILL_EDI_OVERRIDES = loader('./copayRequests/updateCopayRequestFillEdiOverrides.gql')
export const UPDATE_COPAY_REQUEST_FILL_EDI_REVERSAL_OVERRIDES = loader(
  './copayRequests/updateCopayRequestFillEdiReversalOverrides.gql',
)
export const UPDATE_COPAY_REQUEST_FILL_INSURANCE_DURS = loader(
  './copayRequests/updateCopayRequestFillInsuranceDurs.gql',
)
export const UPDATE_COPAY_REQUEST_FILL_INSURANCE_OVERRIDE_CODES = loader(
  './copayRequests/updateCopayRequestFillInsuranceOverrideCodes.gql',
)
export const UPDATE_COPAY_REQUEST_FILL_INSURANCE_COB = loader('./copayRequests/updateCopayRequestFillInsuranceCob.gql')
export const REVERSE_ACTIVE_COPAY_CLAIMS = loader('./copayRequests/reverseActiveCopayClaims.gql')
export const GET_COPAY_REQUESTS_WITH_SHORT_FILL_CODE = loader('./copayRequests/getWithShortFillCode.gql')
export const SEND_BACK_TO_PENDING = loader('./copayRequests/sendBackToPending.gql')
export const SET_COPAY_IN_PA = loader('./copayRequests/setInPA.gql')

// Customer
export const LIST_CUSTOMERS = loader('./customers/list.gql')
export const GET_CUSTOMER = loader('./customers/get.gql')
export const SUBSCRIBE_CUSTOMER = loader('./customers/subscribeCustomer.gql')

// Document
export const CREATE_DOCUMENT = loader('./documents/create.gql')
export const GET_DOCUMENTS = loader('./documents/get.gql')
export const UPDATE_DOCUMENT = loader('./documents/update.gql')
export const GET_DOCUMENT_URL = loader('./documents/getDocumentUrl.gql')

// Escript
export const GET_ESCRIPT = loader('./escripts/get.gql')
export const LIST_ESCRIPTS = loader('./escripts/list.gql')
export const LIST_ESCRIPT_VALIDATION_ISSUE_TYPES = loader('./escripts/getEscriptValidationIssueTypes.gql')
export const GET_ESCRIPT_IN_TRIAGE_COUNT = loader('./escripts/getInTriageCount.gql')
export const GET_ESCRIPT_UNASSIGNED_COUNT = loader('./escripts/getUnassignedCount.gql')
export const GET_ESCRIPT_INTAKE_ISSUES_COUNT = loader('./escripts/getIntakeIssuesCount.gql')
export const UPDATE_ESCRIPT_CUSTOMER = loader('./escripts/updateCustomer.gql')
export const UPDATE_ESCRIPT_EXTERNAL_ID = loader('./escripts/updateExternalId.gql')
export const RESOLVE_ESCRIPT_TRIAGE = loader('./escripts/resolveTriage.gql')
export const SEARCH_ESCRIPT_MEDICATIONS = loader('./escripts/searchMedications.gql')
export const RESOLVE_RX_INTAKE_ISSUES = loader('./escripts/resolveRxIntakeIssues.gql')
export const REJECT_RX = loader('./escripts/rejectRx.gql')

// Fulfillment
export const CONFIRM_FILL_ADJUDICATION = loader('./fulfillment/confirmFillAdjudication.gql')
export const CONFIRM_COPAY_REQUEST_FILL = loader('./fulfillment/confirmCopayRequestFill.gql')
export const CONFIRM_FILL_PV1 = loader('./fulfillment/confirmFillPV1.gql')
export const CONFIRM_FILL_PV2 = loader('./fulfillment/confirmFillPV2.gql')
export const CAN_CONFIRM_PV2 = loader('./fulfillment/canConfirmPV2.gql')
export const CONFIRM_FILL_SCAN = loader('./fulfillment/confirmFillScan.gql')
export const CONFIRM_PACKING = loader('./fulfillment/confirmPacking.gql')
export const FORCE_STATUS_TO_COMPLETE = loader('./fulfillment/forceStatusToComplete.gql')
export const CREATE_ANNOTATION = loader('./fulfillment/createAnnotation.gql')
export const CREATE_LOG = loader('./fulfillment/createLog.gql')
export const CREATE_MESSAGE = loader('./fulfillment/createMessage.gql')
export const CREATE_NOTE = loader('./fulfillment/createNote.gql')
export const UPDATE_NOTE = loader('./fulfillment/updateNote.gql')
export const CREATE_PREVIEW_RX_LABEL = loader('./fulfillment/createPreviewRxLabel.gql')
export const GENERATE_SHIPPING_LABEL = loader('./fulfillment/generateShippingLabel.gql')
// export const GET_FILL_LOGS = loader('./fulfillment/getLogs.gql')
export const GET_NEXT_FILL_ON_ORDER = loader('./fulfillment/getNextFillOnOrder.gql')
export const GET_NEXT_PV1 = loader('./fulfillment/getNextPV1.gql')
export const GET_NEXT_OTC_PACKING = loader('./fulfillment/getNextOtcPacking.gql')
export const GET_NEXT_ADJUDICATION = loader('./fulfillment/getNextAdjudication.gql')
// export const GET_NOTES = loader('./fulfillment/getNotes.gql')
export const GET_DUR_ALLERGIES = loader('./fulfillment/dur/getAllergies.gql')
export const GET_DUR_CONDITIONS = loader('./fulfillment/dur/getConditions.gql')
export const GET_DUR_MEDICATIONS = loader('./fulfillment/dur/getMedications.gql')
export const PRINT_RX_LABEL = loader('./fulfillment/printRxLabel.gql')
export const PRINT_SHIPPING_LABEL = loader('./fulfillment/printShippingLabel.gql')
export const REJECT_RX_FILL_REQUEST = loader('./fulfillment/rejectRxFillRequest.gql')
export const REMOVE_FROM_AUTOMATION = loader('./fulfillment/removeFromAutomation.gql')
export const SEND_TO_MANUAL = loader('./fulfillment/sendToManual.gql')
export const BULK_SEND_TO_MANUAL = loader('./fulfillment/bulkSendToManual.gql')
export const BULK_SEND_DEFERRED_TO_AUTOMATION = loader('./fulfillment/bulkSendDeferredToAutomation.gql')
export const REVERSE_FILL = loader('./fulfillment/reverseFill.gql')
export const SEND_BACK_TO_PV1 = loader('./fulfillment/sendBackToPV1.gql')
export const SEND_BACK_TO_PV1_AND_TRIAGE = loader('./fulfillment/sendBackToPV1AndTriage.gql')
export const SEND_BACK_TO_FILL = loader('./fulfillment/sendBackToFill.gql')
export const SEND_BACK_TO_ADJUDICATION = loader('./fulfillment/sendBackToAdjudication.gql')
export const SEND_TO_AUTOMATION = loader('./fulfillment/sendToAutomation.gql')
export const SET_DISPENSED = loader('./fulfillment/setDispensed.gql')
export const SYNC_AUTOMATION_STATUS = loader('./fulfillment/syncAutomationStatus.gql')
export const BULK_SYNC_AUTOMATION_STATUS = loader('./fulfillment/bulkSyncAutomationStatus.gql')
export const UNCANCEL_FILL = loader('./fulfillment/uncancelFill.gql')
export const UPDATE_DUR_SCREEN = loader('./fulfillment/dur/update.gql')
export const RUN_DUR_SCREEN = loader('./fulfillment/dur/runDurScreen.gql')
export const REVERSE_ACTIVE_CLAIMS = loader('./fulfillment/reverseActiveClaims.gql')

// Otc
export const GET_OTC_BY_SKUS = loader('./otc/getOtcBySKUS.gql')

// Packing material
export const GET_PACKING_MATERIALS_BY_SKUS = loader('./packingMaterials/getPackingMaterialsBySKUS.gql')

// Insurance
export const SEARCH_INSURANCE_PROVIDER = loader('./insurance/searchInsuranceProvider.gql')
export const SEARCH_INSURANCE_PROVIDER_WITH_PAGINATION = loader('./insurance/searchInsuranceProviderPaginated.gql')

// Inventory
export const GET_INVENTORY_FROM_NDC_SUB = loader('./inventory/getInventoryFromNdcSubstitution.gql')
export const GET_INVENTORY_ADJUSTMENTS_BY_NDC = loader('./inventory/getInventoryAdjustmentsByNdc.gql')
export const GET_EXPECTED_LOTS = loader('./inventory/getExpectedLots.gql')

// Pods
export const GET_ALL_PODS = loader('./pods/getAll.gql')

// Inventory Group
export const CREATE_INVENTORY_GROUP = loader('./inventoryGroup/create.gql')
export const UPDATE_INVENTORY_GROUP = loader('./inventoryGroup/update.gql')
export const LIST_INVENTORY_GROUPS = loader('./inventoryGroup/list.gql')
export const GET_INVENTORY_GROUP = loader('./inventoryGroup/get.gql')
export const GET_INVENTORY_GROUP_BY_PIONEER_ALIAS = loader('./inventoryGroup/getInventoryGroupByPioneerAlias.gql')
export const GET_INVENTORY_GROUP_BY_CUSTOMER_ID = loader('./inventoryGroup/getInventoryGroupByCustomerId.gql')
export const GET_DEFAULT_INVENTORY_GROUP = loader('./inventoryGroup/getDefaultInventoryGroup.gql')

// Dispensing Decision
export const GET_DISPENSING_DECISIONS = loader('./dispensingDecision/getDispensingDecisions.gql')

// Critical Medical Notes
export const NEW_CRITICAL_MEDICAL_NOTE = loader('./criticalMedicalNotes/new.gql')
export const UPDATE_CRITICAL_MEDICAL_NOTE = loader('./criticalMedicalNotes/updateByIdentifier.gql')
export const GET_CRITICAL_MEDICAL_NOTE_BY_NDC = loader('./criticalMedicalNotes/getNoteByNdc.gql')
export const ARCHIVE_CRITICAL_MEDICAL_NOTE = loader('./criticalMedicalNotes/archive.gql')

// Locations
export const GET_LOCATION = loader('./locations/get.gql')
export const UPDATE_LOCATION = loader('./locations/update.gql')
export const LIST_LOCATIONS = loader('./locations/list.gql')
export const SUBSCRIBE_LOCATION = loader('./locations/subscribeLocation.gql')

// Logs //
export const GET_LOGS = loader('./logs/getLogs.gql')
export const GET_LOGS_ORDER = loader('./logs/getOrderLogs.gql')
export const GET_USER_LOGS = loader('./logs/getUserLogs.gql')
export const LOG_PAGE_VIEW = loader('./logs/logPageView.gql')
export const SUBSCRIBE_NOTES = loader('./logs/subscribeNotes.gql')

// MFA
export const CREATE_TEMPORARY_MFA = loader('./users/createTemporaryMFA.gql')
export const CONVERT_TEMPORARY_MFA_SECRET = loader('./users/convertTemporaryMFASecret.gql')

// monograph
export const PRINT_MONOGRAPH = loader('./drugs/printMonograph.gql')

// NDCs
export const GET_ALLOWED_SUBS = loader('./ndcs/getAllowedSubs.gql')
export const GET_DRUG_IMPRINT = loader('./ndcs/getDrugImprint.gql')
export const GET_NDC_FULL_INFO = loader('./ndcs/getNdcFullInfo.gql')
export const GET_MONOGRAPH = loader('./ndcs/getMonograph.gql')
export const SEARCH_NDC_FULL_INFO = loader('./ndcs/searchNdcFullInfo.gql')
export const SEARCH_NDC_PARTIAL_INFO = loader('./ndcs/searchNdcPartialInfo.gql')
export const SEARCH_GROUPED_NDC_INFO = loader('./ndcs/searchGroupedNdcInfo.gql')
export const GET_NDC_ALTERNATE_BAR_CODES = loader('./ndcs/getNdcAlternateBarCodes.gql')

// Order
export const ADD_FIXTURE_ORDER = loader('./orders/addFixtureOrder.gql')
export const ADD_ORDER_USER_EVENT = loader('./orders/addUserEvent.gql')
export const ADD_ORDER_INSURANCE = loader('./orders/addOrderInsurance.gql')
export const ADD_ORDER_INSURANCE_OPTION = loader('./orders/addOrderInsuranceOption.gql')
export const CANCEL_ORDER = loader('./orders/cancel.gql')
export const RESET_ORDER = loader('./orders/reset.gql')
export const CLEAR_ORDER_LOCK = loader('./orders/clearLock.gql')
export const CREATE_ORDER = loader('./orders/create.gql')
export const CREATE_SNAP_RETURN = loader('./orders/createSnapReturn.gql')
export const CREATE_SNAP_REPLACE = loader('./orders/createSnapReplace.gql')
export const GET_API_REQUEST_PARAMS = loader('./orders/getApiRequestParams.gql')
export const GET_CS_ORDER = loader('./orders/getCSOrder.gql')
export const GET_FULL_ORDER = loader('./orders/getFullOrder.gql')
export const GET_BASIC_ORDER = loader('./orders/getBasicOrder.gql')
export const GET_NEXT_TRIAGE = loader('./orders/getNextTriage.gql')
export const GET_ORDER_BY_CORE_ORDER_TOKEN = loader('./orders/getOrderByCoreOrderToken.gql')
export const GET_ORDER_BY_CORE_ORDER_ID = loader('./orders/getOrderByCoreOrderId.gql')
export const GET_ORDER_BY_CORE_FILL_REQUEST_TOKEN = loader('./orders/getOrderByCoreFillRequestToken.gql')
export const GET_ORDER_LOCK = loader('./orders/getLock.gql')
export const GET_ORDERS_WITH_SHORT_FILL_CODE = loader('./orders/getWithShortFillCode.gql')
export const GET_RETURN_ITEMS = loader('./orders/getReturnItems.gql')
export const LIST_ORDERS = loader('./orders/list.gql')
export const LIST_FULFILLMENT_ORDERS = loader('./orders/list-fulfillment.gql')
export const REPLACE_RXFILLREQUEST = loader('./orders/replaceRxFillRequest.gql')
export const REROUTE_ORDER = loader('./orders/reroute.gql')
export const RESOLVE_TRIAGE = loader('./orders/resolveTriage.gql')
export const REVERT_TO_CORE = loader('./orders/revertToCore.gql')
export const RUN_THROUGH_CLAIM_JUMPER = loader('./orders/runThroughClaimJumper.gql')
export const SEARCH_ORDER_MEDICATIONS = loader('./orders/searchMedications.gql')
export const SEARCH_ORDER_TRIAGE_REASONS = loader('./orders/searchTriageReasons.gql')
export const SET_IN_TRIAGE = loader('./orders/setInTriage.gql')
export const SET_ORDER_IN_PA = loader('./orders/setOrderInPA.gql')
export const SET_ORDER_LOCK = loader('./orders/setLock.gql')
export const GET_ORDER_INSURANCE_OPTION_IMAGES = loader('./orders/getInsuranceOptionImages.gql')
export const SUBSCRIBE_ORDER = loader('./orders/subscribeOrder.gql')
export const UPDATE_OTC_SKUS = loader('./orders/updateOtcSkus.gql')
export const REMOVE_ORDER_INSURANCE = loader('./orders/removeOrderInsurance.gql')
export const REMOVE_ORDERS_FROM_AUTOMATION = loader('./orders/removeOrdersFromAutomation.gql')
export const RETURN_ORDER = loader('./orders/returnOrder.gql')
export const SET_ORDER_INSURANCE = loader('./orders/setOrderInsurance.gql')
export const UPDATE_SHIPPING_ADDRESS = loader('./orders/updateShippingAddress.gql')
export const UPDATE_SHIPPING_METHOD = loader('./orders/updateShippingMethod.gql')
export const UPDATE_TRACKING_NUMBER = loader('./orders/updateTrackingNumber.gql')
export const UPDATE_PAYMENT_TYPE = loader('./orders/updatePaymentType.gql')
export const UPDATE_ORDER_IS_REPLACEMENT = loader('./orders/updateIsReplacement.gql')
export const UPDATE_ORDER_IS_RESERVED_FOR_INVESTIGATION = loader('./orders/updateIsReservedForInvestigation.gql')
export const UPDATE_ORDER_INSURANCE = loader('./orders/updateOrderInsurance.gql')
export const UPDATE_ORDER_COVER_MY_MEDS_KEY = loader('./orders/updateCoverMyMedsKey.gql')
export const UPDATE_ORDER_FILL_REQUEST_INSURANCE_DURS = loader('./orders/updateOrderFillRequestInsuranceDurs.gql')
export const UPDATE_ORDER_FILL_REQUEST_INSURANCE_OVERRIDE_CODES = loader(
  './orders/updateOrderFillRequestInsuranceOverrideCodes.gql',
)
export const UPDATE_ORDER_FILL_REQUEST_INSURANCE_COB = loader('./orders/updateOrderFillRequestInsuranceCob.gql')
export const UPDATE_ORDER_FILL_REQUEST_EDI_OVERRIDES = loader('./orders/updateOrderFillRequestEdiOverrides.gql')
export const UPDATE_ORDER_FILL_REQUEST_EDI_REVERSAL_OVERRIDES = loader(
  './orders/updateOrderFillRequestEdiReversalOverrides.gql',
)
export const GET_ADJUDICATION_REJECT_CODES = loader('./orders/getAdjudicationRejectCodes.gql')
export const GET_ORDERS_BY_AUTOMATION_COUNT = loader('./orders/getOrdersCountByAutomationStatus.gql')
export const TRACK_CONTROLLED_SUBSTANCE_REJECTIONS = loader('./orders/trackControlledSubstanceRejections.gql')
export const CONFIRM_EXCESSIVE_CS_REJECTIONS_REPORT = loader('./orders/confirmExcessiveCsRejectionsReport.gql')
export const OVERRIDE_OTC_PRODUCT = loader('./orders/overrideOtcProduct.gql')
export const OVERRIDE_PACKING_MATERIAL = loader('./orders/overridePackingMaterial.gql')
export const PRINT_PICK_SLIPS = loader('./orders/printPickSlips.gql')

// Outbound Transfers
export const CONFIRM_OUTBOUND_TRANSFER_PENDING = loader('./outboundTransfers/confirmOutboundTransferPending.gql')
export const GET_BASIC_OUTBOUND_TRANSFER = loader('./outboundTransfers/getBasicOutboundTransfer.gql')
export const REJECT_OUTBOUND_TRANSFER = loader('./outboundTransfers/rejectOutboundTransfer.gql')
export const SET_OUTBOUND_TRASNFER_IN_TRIAGE = loader('./outboundTransfers/setOutboundTransferInTriage.gql')
export const RESOLVE_OUTBOUND_TRANSFER_TRIAGE = loader('./outboundTransfers/resolveOutboundTransferTriage.gql')

// ParataNDC
export const ADD_PARATA_NDC = loader('./parataNdcs/add.gql')
export const DELETE_PARATA_NDC = loader('./parataNdcs/delete.gql')
export const SEARCH_PARATA_NDCS = loader('./parataNdcs/search.gql')
export const UPDATE_PARATA_NDC = loader('./parataNdcs/update.gql')

// Patient
export const CREATE_PATIENT = loader('./patients/create.gql')
export const GET_PATIENT = loader('./patients/get.gql')
export const GET_PATIENT_PRESCRIPTIONS = loader('./patients/getPrescriptions.gql')
export const GET_PATIENT_CUSTOMERS = loader('./patients/getPatientCustomers.gql')
export const GET_PATIENT_NOTES = loader('./patients/getNotes.gql')
export const SEARCH_PATIENTS = loader('./patients/search.gql')
export const UPDATE_GUEST_PATIENT = loader('./patients/updateGuest.gql')
export const UPDATE_PATIENT = loader('./patients/update.gql')
export const UPDATE_PATIENT_INSURANCE = loader('./patients/updatePatientInsurance.gql')
export const UPDATE_PATIENT_STATUS = loader('./patients/updatePatientStatus.gql')
export const SUBSCRIBE_PATIENT = loader('./patients/subscribePatient.gql')
export const RUN_ELIGIBILITY_CHECK = loader('./patients/runEligibilityCheck.gql')
export const GET_PATIENT_MATCH_COUNT = loader('./patients/getPatientMatchCount.gql')
export const GET_NEXT_PATIENT_MATCH = loader('./patients/getNextPatientMatch.gql')

// PatientMatch
export const LIST_PATIENT_MATCHES = loader('./patientMatches/list.gql')
export const GET_PATIENT_MATCH = loader('./patientMatches/getPatientMatch.gql')
export const SUBSCRIBE_PATIENT_MATCH = loader('./patientMatches/subscribePatientMatch.gql')
export const RESOLVE_PATIENT_MATCH = loader('./patientMatches/resolvePatientMatch.gql')
export const REJECT_PATIENT_MATCH = loader('./patientMatches/rejectPatientMatch.gql')
export const REJECT_PATIENT_MATCH_AND_ERX = loader('./patientMatches/rejectPatientMatchAndErx.gql')
export const SET_PATIENT_MATCH_LOCK = loader('./patientMatches/setLock.gql')
export const CLEAR_PATIENT_MATCH_LOCK = loader('./patientMatches/clearLock.gql')

// Payer
export const CREATE_NEW_PAYER = loader('./insurance/create.gql')
export const GET_PAYER = loader('./insurance/get.gql')
export const SEARCH_PAYERS = loader('./insurance/search.gql')
export const UPDATE_PAYER = loader('./insurance/update.gql')
export const GET_PAYER_NOTES = loader('./insurance/getNotes.gql')

// Pharmacies
export const GET_PHARMACY = loader('./pharmacies/get.gql')
export const SEARCH_PHARMACY = loader('./pharmacies/searchPharmacy.gql')

// Prescriber
export const GET_PRESCRIBER = loader('./prescribers/get.gql')
export const GET_SYNCED_PRESCRIBER = loader('./prescribers/getSynced.gql')
export const GET_PRESCRIBER_NOTES = loader('./prescribers/getNotes.gql')
export const SEARCH_PRESCRIBERS = loader('./prescribers/search.gql')
export const UNIFIED_SEARCH_PRESCRIBERS = loader('./prescribers/unifiedSearch.gql')
export const ADD_PRESCRIBER = loader('./prescribers/addPrescriber.gql')
export const ADD_PRESCRIBER_PRACTICE = loader('./prescribers/addPractice.gql')
export const UPDATE_PRESCRIBER_PRACTICE = loader('./prescribers/updatePractice.gql')
export const GET_PRESCRIBER_PRACTICE_CHANGES = loader('./prescribers/getPracticeChanges.gql')

// Prescription
export const CREATE_PRESCRIPTION = loader('./prescriptions/create.gql')
export const GET_ANNOTATIONS = loader('./prescriptions/getAnnotations.gql')
export const GET_PRESCRIPTION = loader('./prescriptions/get.gql')
export const LIST_PRESCRIPTIONS = loader('./prescriptions/list.gql')
export const LIST_PRESCRIPTIONS_INCLUDING_CONTROLLED_SUBSTANCES = loader(
  './prescriptions/listIncludingControlledSubstances.gql',
)

export const REQUEST_RENEWAL = loader('./prescriptions/requestRenewal.gql')
export const RUN_TAKEOVER_RX = loader('./prescriptions/takeoverRx.gql')
export const SEARCH_ICD10 = loader('./prescriptions/searchIcd10.gql')
export const UPDATE_PRESCRIPTION = loader('./prescriptions/update.gql')
export const UPDATE_PRESCRIBED = loader('./prescriptions/updatePrescribed.gql')
export const UPDATE_PRESCRIBED_CUSTOMER = loader('./prescriptions/updatePrescribedCustomer.gql')
export const GET_PREFERRED_SUBSTITUTION = loader('./prescriptions/getPreferredSubstitutions.gql')
export const SUBSCRIBE_PRESCRIPTION = loader('./prescriptions/subscribePrescription.gql')
export const SEARCH_PRESCRIPTION_MEDICATIONS = loader('./prescriptions/searchMedications.gql')
export const DISCONTINUE_PRESCRIPTION = loader('./prescriptions/discontinue.gql')
export const REINSTATE_PRESCRIPTION = loader('./prescriptions/reinstate.gql')
export const GET_DRUG_DETAILS = loader('./prescriptions/getDrug.gql')
export const COORDINATE_TRANSFER = loader('./prescriptions/coordinateTransfer.gql')
export const GET_RX_FILL_HISTORY_IMAGE = loader('./prescriptions/getPrescriptionTransferHistoryImage.gql')
export const VERIFY_TRANSFER_OUT = loader('./prescriptions/verifyTransferOut.gql')
export const GENERATE_RX_CHANGE_IMAGE = loader('./prescriptions/generateAndSendPrescriptionRxChangeImage.gql')
export const GENERATE_AND_SEND_RX_RENEWAL_IMAGE = loader('./prescriptions/generateAndSendPrescriptionRenewalImage.gql')
export const ADD_ATTACHMENT_TO_RX_IMAGE = loader('./prescriptions/addAttachmentToRxImage.gql')
export const REMOVE_ATTACHMENT_FROM_RX_IMAGE = loader('./prescriptions/removeAttachmentFromRxImage.gql')
export const REDOWNLOAD_PRESCRIBED_IMAGE = loader('./prescriptions/redownloadPrescribedImage.gql')
export const GET_PRESCRIPTION_WARNINGS = loader('./prescriptions/getPrescriptionWarnings.gql')

export const LIST_PRINTERS = loader('./printers/list.gql')
export const SUBSCRIBE_PRINTER = loader('./printers/subscribePrinter.gql')
export const UPDATE_PRINTER = loader('./printers/update.gql')
export const NEW_PRINTER = loader('./printers/new.gql')
export const CALIBRATE_PRINTER = loader('./printers/calibrate.gql')
export const PRINTER_STATUS = loader('./printers/status.gql')

// Prior Authorizations
export const LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS = loader('./priorAuthorizations/list-fulfillment.gql')
export const GET_ORDER_PRIOR_AUTHORIZATION_COUNT = loader('./priorAuthorizations/getOrderPriorAuthorizationCount.gql')
export const GET_PRIOR_AUTHORIZATION = loader('./priorAuthorizations/getPriorAuthorization.gql')
export const INITIATE_PA = loader('./priorAuthorizations/initiatePA.gql')
export const UPDATE_PRIOR_AUTHORIZATION = loader('./priorAuthorizations/update.gql')

// Sessions
export const GET_SESSIONS_FOR_USER = loader('./session/getForUser.gql')
export const END_USER_SESSION = loader('./session/endSession.gql')

// Sig Codes
export const CREATE_SIG_CODE = loader('./sigCodes/create.gql')
export const UPDATE_SIG_CODE = loader('./sigCodes/update.gql')
export const LIST_SIG_CODES = loader('./sigCodes/list.gql')
export const REMOVE_SIG_CODE = loader('./sigCodes/remove.gql')
export const SEARCH_SIG_CODE = loader('./sigCodes/searchSigCode.gql')
export const GET_SIG_CODE = loader('./sigCodes/getSigCode.gql')

// Users
export const CHANGE_PASSWORD = loader('./users/changePassword.gql')
export const CREATE_USER = loader('./users/create.gql')
export const LIST_USERS = loader('./users/list.gql')
export const RESET_USER_PASSWORD = loader('./users/resetPassword.gql')
export const SEARCH_USERS = loader('./users/searchUsers.gql')
export const SEARCH_PHARMACISTS = loader('./users/searchPharmacists.gql')
export const SUBSCRIBE_USER = loader('./users/subscribeUser.gql')
export const GET_USER = loader('./users/getUser.gql')
export const UPDATE_USER_INFO_FROM_AUTH0 = loader('./users/updateUserInfoFromAuth0.gql')
export const ME = loader('./users/me.gql')
export const LAST_CHECKED_RELEASE_NOTES = loader('./users/lastCheckedReleaseNotes.gql')
export const UPDATE_LAST_CHECKED_RELEASE_NOTES = loader('./users/updateLastCheckedReleaseNotes.gql')
export const GET_USERS_BY_LOCATION = loader('./users/getUsersByLocation.gql')
export const UPDATE_USER = loader('./users/update.gql')

// External Transfers
export const LIST_EXTERNAL_TRANSFERS = loader('./externalTransfers/list.gql')
export const GET_EXTERNAL_TRANSFER = loader('./externalTransfers/get.gql')
export const PATCH_EXTERNAL_TRANSFER = loader('./externalTransfers/patch.gql')
export const FAX_EXTERNAL_TRANSFER = loader('./externalTransfers/fax.gql')
