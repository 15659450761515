import { Link, useLocation } from '@truepill/tpos-react-router'
import { FillStatus, OrderStatus, OrderType, RxFillRequestStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse-yellow.svg'
import AuthLimited from 'components/AuthLimited'
import Bell from 'components/BellIcon'
import IconWrapper from 'components/IconWrapper'
import { ReplacePrescriptionButton, UncancelFillButton } from 'components/OrderView/components'
import RejectFillButton from 'components/OrderView/components/RejectFillButton'
import { VerticalStackItemStyle } from 'components/RXTable'
import SpeciesLogo from 'components/SpeciesLogo'
import HandlingTagsTile from 'components/Tiles/HandlingTagsTile'
import Lozenge from 'components/Tiles/Lozenge'
import PrintedTile from 'components/Tiles/PrintedTile'
import RobotTile from 'components/Tiles/RobotTile'
import UserInitialsBadge from 'components/UserInitialsBadge'
import { CheckBox } from 'grommet'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import moment from 'moment'
import type { Selectable } from 'providers/SelectionProvider'
import { useSelectionContext } from 'providers/SelectionProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToFulfillmentFill, goToFulfillmentOrder } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  accentPink,
  alertRed,
  contrastColor,
  darkGreen,
  darkOrange,
  lightOrange,
  primaryBackgroundColor,
  bodySecondaryColor,
  highlightPrimaryColor,
  warningYellow,
  confirmColor,
  darkBlue,
} from 'styles/styleVariables'
import { FillWorkStatus, MachineStatus } from 'types'
import type { Fill, MachineOptions, Order, Prescription, RXFillRequest } from 'types'
import { formatCreatedDate } from 'utils'
import { isHuman } from 'utils/Patient'
import { LinkWrapper } from '.'
import ClaimRejectionReasons from './ClaimRejectionReasons'

const dayMs = 1000 * 60 * 60 * 24

const shouldShowNdcAndQuantity = (order: Order) => [OrderStatus.Fill, OrderStatus.Automation].includes(order.status)

const getClaimAge = (claimRanDate: Date | undefined) => {
  if (!claimRanDate) {
    return ''
  }

  const diff = Date.now() - new Date(claimRanDate).getTime()

  return diff < 8 * dayMs ? '' : `${Math.floor(diff / dayMs)} claim day${diff >= 2 * dayMs ? 's' : ''}`
}

const isDuplicateFill = (prescription: Prescription, fill: Fill) => {
  return prescription.fills.filter((f: Fill) => f.status === FillStatus.InFulfillment && f._id !== fill._id).length > 0
}

interface BaseOrderProps {
  inline?: boolean
  hideOrderDetails?: boolean
  editableEntries?: boolean
  showFillsOnly?: boolean
}

interface OrderProps extends BaseOrderProps {
  order: Order
  selectionLive?: boolean
  noIndividualFillSelection?: boolean
  disabled?: boolean
  disabledCheckbox?: boolean
  filterOnQueue?: boolean
  onClick?: () => void
}

interface FillProps extends Fill {
  isMultiOrder?: boolean
  inline?: boolean
  workStatus: FillWorkStatus
}

export const FillRow = ({
  visible = true,
  noIndividualFillSelection,
  onClick,
  disabled,
  order,
  editableEntries = false,
  isRxComingFromOrderPage = false,
  fill: {
    _id,
    prescription,
    fillId,
    fill,
    status,
    machine,
    paymentType,
    isReAttempt,
    adjudication,
    specialHandlingTags,
    otcProductId,
    patient: fillPatient,
  },
  showAsBatchOrder,
  disabledCheckbox,
  inline,
  selectionLive,
  showOTCTag,
}: {
  noIndividualFillSelection?: boolean
  order: Order
  disabled: boolean
  disabledCheckbox: boolean
  fill: RXFillRequest
  showOTCTag?: boolean
  selectionLive?: boolean
  inline?: boolean
  visible?: boolean
  showAsBatchOrder: boolean
  editableEntries?: boolean
  isRxComingFromOrderPage?: boolean
  onClick?: () => void
}) => {
  const { tasks } = useTaskContext()
  const { search } = useLocation()
  const fulfillmentQueue = useFulfillmentQueue()

  const isTriageQueue = fulfillmentQueue?.name === FulfillmentQueueName.Triage
  const isPackingQueue = fulfillmentQueue?.name === FulfillmentQueueName.Packing
  const { date: createdAtDate, greaterThan48 } = formatCreatedDate(order.createdAt)
  const { getLocationNameById, getCustomerNameById } = useTPCacheContext()

  const isOtcAdjudicationProduct = !!otcProductId
  const taskFound = tasks.find(task => task.code === fill.shortFillCode)
  const showScanCheckbox = order.status === OrderStatus.Packing && taskFound && !isOtcAdjudicationProduct
  // keeping order.patient for case rxAndOTCAdjudicationGrouped from OrderView
  const patient = fillPatient || order.patient
  const showCompletedTask = taskFound?.completed
  const dateOkToFill =
    isTriageQueue && order.triages.find(t => t.fillId === fillId && t.details?.dateOkToFill)?.details?.dateOkToFill
  const splitMachineName = machine?.deviceName && machine?.deviceName.split('_')
  const parsedMachineName =
    splitMachineName && splitMachineName.length > 2
      ? `${splitMachineName[1]} ${splitMachineName[2]}`
      : machine?.deviceName
  const isMultiPetOrder = order.orderType === OrderType.MultiPet

  if (!visible) {
    return <></>
  }

  return (
    <LinkWrapper
      disabled={fill.status === FillStatus.Cancelled || status === RxFillRequestStatus.Cancelled}
      withRemoveIcon={editableEntries}
      withUncancelIcon={fill.status === FillStatus.Cancelled && order.status !== OrderStatus.Complete}
      key={`${order._id} ${fill?._id}`}
    >
      <StyledLink
        onClick={onClick}
        to={
          // When an order status is NOT packing, we want the order to go directly to Fill page. On the other hand,
          // we want orders with PACKING status to go to the Order page first and then access to the Fill page once they click on the OrderEntry FillRow component,
          // found in OrderView.tsx (JR-18241)
          fill && order.status !== OrderStatus.Packing
            ? goToFulfillmentFill({
                fulfillmentQueueName: fulfillmentQueue?.name,
                orderId: order._id,
                fillId: fill._id,
                search,
              })
            : isRxComingFromOrderPage
            ? goToFulfillmentFill({
                fulfillmentQueueName: fulfillmentQueue?.name,
                orderId: order._id,
                fillId: fill._id,
                search,
              })
            : goToFulfillmentOrder({
                fulfillmentQueueName: fulfillmentQueue?.name ?? 'all',
                orderId: order._id,
                search,
              })
        }
      >
        <StyledFill
          id={`Fill-${fillId}`}
          order={order}
          inline={inline}
          data-fill-id={fillId}
          data-fill-exists={!!fill}
          data-order-id={order._id}
          isMultiOrder={showAsBatchOrder}
          workStatus={FillWorkStatus.normal}
          data-rx-number={prescription?.rxNumber}
          data-testid="Fill"
          {...fill}
        >
          <RXNumber data-testid="rx-number" fade={!!order.lock || disabled}>
            {selectionLive &&
            !(noIndividualFillSelection && showAsBatchOrder) &&
            fill?.status !== FillStatus.Cancelled ? (
              isPackingQueue ? (
                <PackingSelectedCheckbox order={order} disabled={disabled} />
              ) : (
                <FillSelectedCheckbox fill={fill} order={order} disabled={disabled || disabledCheckbox} />
              )
            ) : (
              showAsBatchOrder && selectionLive && <EmptyBlock />
            )}
            {showScanCheckbox && (
              <ScanCheckbox completed={showCompletedTask}>{showCompletedTask && <CheckboxIcon />}</ScanCheckbox>
            )}
            {!isOtcAdjudicationProduct ? `RX: ${prescription?.rxNumber}` : `OTC RX: ${prescription?.rxNumber}`}
          </RXNumber>
          {dateOkToFill && order.status !== OrderStatus.Adjudication ? (
            <DateStampWithRefillDate data-testid="date-created">
              {`${createdAtDate} ${greaterThan48} `}
              {`${dateOkToFill ? `(Refillable ${moment(dateOkToFill).format('MM/DD/YYYY')})` : ''}`}
            </DateStampWithRefillDate>
          ) : (
            <DateStamp data-testid="date-created">{`${createdAtDate} ${greaterThan48}`}</DateStamp>
          )}
          <ClaimAge>{getClaimAge(adjudication?.claimRunDate)}</ClaimAge>
          {order.status === OrderStatus.Adjudication && (
            <ClaimRejections>
              <ClaimRejectionReasons
                claims={adjudication?.lastClaimRejectCodes ? adjudication?.lastClaimRejectCodes : []}
              />
            </ClaimRejections>
          )}
          {/* TODO/Tech-debt: Consider split this component into multiple variations since it's doing too much already */}
          {(!isRxComingFromOrderPage || !isMultiPetOrder) && (
            <>
              <Species>{!!patient && <SpeciesLogo isHuman={isHuman(patient)} />}</Species>
              <PatientName data-testid="patient-name" fade={!!order.lock || disabled} data-private>
                {patient ? (
                  <>
                    {patient?.firstName} {patient?.lastName}
                  </>
                ) : (
                  <Lozenge data-testid="patient-missing" backgroundColor={alertRed} color={primaryBackgroundColor}>
                    This fill request is missing a patient!
                  </Lozenge>
                )}
              </PatientName>
            </>
          )}

          <Drug
            data-testid="medication"
            isRxComingFromOrderPage={isRxComingFromOrderPage}
            fade={(!!order.lock || disabled) && isRxComingFromOrderPage}
            shouldDisplayMultiPetVersion={isMultiPetOrder}
          >
            {fill?.dispensed.name}
            <DrugNdcWrapper>{shouldShowNdcAndQuantity(order) && `(${fill?.dispensed.ndc})`}</DrugNdcWrapper>
          </Drug>
          {shouldShowNdcAndQuantity(order) && (
            <DrugQuantityWrapper>Qty: {fill?.dispensed.quantity}</DrugQuantityWrapper>
          )}
          <Statuses>
            {!!order.priority && <Bell />}
            {order.printPickSlipsHistory && order.printPickSlipsHistory?.length > 0 && (
              <Lozenge backgroundColor="#F8F1FF">
                <IconWrapper>
                  <PrintIcon fill="black" />
                </IconWrapper>
              </Lozenge>
            )}
            {showOTCTag && (
              <Lozenge data-testid="otc-only" backgroundColor="#F8F1FF" color="#310063">
                Otc
              </Lozenge>
            )}
            {status === RxFillRequestStatus.Automation && machine?.isDeferred && (
              <Lozenge data-testid="autobot-name" color="#007AD1" backgroundColor="#DBF0FF">
                {parsedMachineName}
              </Lozenge>
            )}
            {status === RxFillRequestStatus.Automation && <AutomationQueueStatus status={machine?.status} />}
            {fill?.isReplacement && (
              <Lozenge data-testid="replacement" color="#AF5304" backgroundColor="#FFF6E8">
                RP
              </Lozenge>
            )}
            {order.isReservedForInvestigation && (
              <Lozenge data-testid="resrvedForInvestigation" color={darkOrange} backgroundColor={lightOrange}>
                reserved
              </Lozenge>
            )}
            {order.inTriage && fulfillmentQueue?.name !== 'Triage' && (
              <Lozenge backgroundColor={highlightPrimaryColor}>Triage</Lozenge>
            )}
            {paymentType === 'insurance' && (
              <Lozenge data-testid="insurance" backgroundColor={darkGreen}>
                insurance
              </Lozenge>
            )}
            <StatusLozenge
              fulfillmentQueueName={fulfillmentQueue?.name}
              orderStatus={order.status}
              rxFillRequestStatus={status}
            />
            <Lozenge data-testid="customer" backgroundColor={contrastColor}>
              {getCustomerNameById(order.customerId)}
            </Lozenge>
            <Lozenge data-testid="location" backgroundColor={accentPink}>
              {getLocationNameById(order.locationId)}
            </Lozenge>
            <HandlingTagsTile specialHandlingTags={specialHandlingTags} />
            {(fill?.labelsPrinted ?? 0) > 0 && <PrintedTile />}
            {isReAttempt && isReAttempt.reason && <StyledRevertPadding />}
            {fill && <StatusIcons machine={machine} status={status} />}
            {!!order.lock && (
              <UserBadges>
                <UserInitialsBadge user={order.lock.user} />
              </UserBadges>
            )}
          </Statuses>
        </StyledFill>
      </StyledLink>
      {editableEntries && (
        <ReplacePrescriptionButton
          orderId={order._id}
          rxFillRequestId={_id}
          patientId={prescription?.patient._id}
          isMultiOrder={showAsBatchOrder}
        />
      )}
      {status !== RxFillRequestStatus.Cancelled && fill.status !== FillStatus.Cancelled && editableEntries && (
        <AuthLimited
          roles={[
            UserRoles.Admin,
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.LeadCustomerSupport,
            UserRoles.CustomerSupport,
          ]}
        >
          <RejectFillButton
            rxNumber={prescription?.rxNumber.toString()}
            orderId={order._id}
            dispensedName={fill?.dispensed.name}
            fill={fill}
          />
        </AuthLimited>
      )}
      {status === RxFillRequestStatus.Cancelled &&
        fill.status === FillStatus.Cancelled &&
        editableEntries &&
        !isDuplicateFill(prescription, fill) && (
          <AuthLimited
            roles={[
              UserRoles.Admin,
              UserRoles.LeadPharmacist,
              UserRoles.Pharmacist,
              UserRoles.LeadCustomerSupport,
              UserRoles.CustomerSupport,
              UserRoles.Technician,
            ]}
          >
            <UncancelFillButton orderId={order._id} fillId={fill._id} />
          </AuthLimited>
        )}
    </LinkWrapper>
  )
}

interface BaseOrderProps {
  inline?: boolean
  hideOrderDetails?: boolean
  editableEntries?: boolean
  showFillsOnly?: boolean
}

interface OtcOrderProps extends BaseOrderProps {
  order: Order
  disabled?: boolean
  onClick?: () => void
  productIndex?: number
  showAsBatch?: boolean
  productName?: string
  withRemoveIcon?: boolean
  selectionLive?: boolean
  rxNumber?: string
}

export const OtcRow = (props: OtcOrderProps): JSX.Element => {
  const {
    order,
    inline,
    showAsBatch,
    productName,
    onClick,
    withRemoveIcon = false,
    disabled = false,
    productIndex = 0,
    selectionLive = false,
    rxNumber,
  } = props
  const { search } = useLocation()
  const fulfillmentQueue = useFulfillmentQueue()
  const { getLocationNameById, getCustomerNameById } = useTPCacheContext()

  const { date: createdAtDate, greaterThan48 } = formatCreatedDate(order.createdAt)
  const firstName = order.guestPatient ? order.guestPatient?.firstName : order.patient?.firstName
  const lastName = order.guestPatient ? order.guestPatient?.lastName : order.patient?.lastName
  const isMultiPetOrder = order.orderType === OrderType.MultiPet
  const patientName = !isMultiPetOrder ? `${firstName} ${lastName}` : ''

  return (
    <LinkWrapper disabled={disabled} withRemoveIcon={withRemoveIcon}>
      <StyledLink
        onClick={onClick}
        to={goToFulfillmentOrder({
          fulfillmentQueueName: fulfillmentQueue?.name ?? 'all',
          orderId: order._id,
          search,
        })}
      >
        <StyledOTCOrder
          id={`OtcOrder-${order._id}-${productIndex}`}
          inline={inline}
          data-order-id={order._id}
          order={order}
        >
          {showAsBatch ? (
            <RXNumber data-testid="otc-index" fade={!!order.lock || disabled}>
              {selectionLive && <EmptyBlock />}
              {!!rxNumber ? `OTC RX: ${rxNumber}` : `OTC - ${productIndex + 1}`}
            </RXNumber>
          ) : (
            <RXNumber data-testid="core-order-id" fade={!!order.lock || disabled}>
              {selectionLive && <PackingSelectedCheckbox order={order} disabled={disabled} />}
              Order: {order.coreOrderId}
            </RXNumber>
          )}
          <DateStamp data-testid="date-created">
            {createdAtDate} {greaterThan48}
          </DateStamp>
          <Species>{order.patient ? <SpeciesLogo isHuman={isHuman(order.patient)} /> : <></>}</Species>
          <PatientName data-testid="patient-name" fade={!!order.lock || disabled} data-private>
            {patientName}
          </PatientName>
          <Drug data-testid="medication" fade={!!order.lock || disabled}>
            {productName}
          </Drug>
          <Statuses>
            {!!order.priority && <Bell />}
            {order.printPickSlipsHistory && order.printPickSlipsHistory?.length > 0 && (
              <Lozenge backgroundColor="#F8F1FF">
                <IconWrapper>
                  <PrintIcon fill="black" />
                </IconWrapper>
              </Lozenge>
            )}
            {order.inTriage && fulfillmentQueue?.name !== 'Triage' && (
              <Lozenge backgroundColor={highlightPrimaryColor} color="#000">
                Triage
              </Lozenge>
            )}
            <Lozenge data-testid="otc-only" backgroundColor="#F8F1FF" color="#310063">
              Otc
            </Lozenge>
            <Lozenge data-testid="customer" backgroundColor={contrastColor}>
              {getCustomerNameById(order.customerId)}
            </Lozenge>
            <Lozenge data-testid="location" backgroundColor={accentPink}>
              {getLocationNameById(order.locationId)}
            </Lozenge>
            {!!order.lock && (
              <UserBadges>
                <UserInitialsBadge user={order.lock.user} />
              </UserBadges>
            )}
          </Statuses>
        </StyledOTCOrder>
      </StyledLink>
    </LinkWrapper>
  )
}

type StatusLozengeProps = {
  fulfillmentQueueName?: string
  rxFillRequestStatus: string
  orderStatus: string
}

type AutomationQueueStatusProps = { status?: string }

const statusQueueMap = {
  [MachineStatus.Error]: { text: 'Error', backgroundColor: alertRed },
  [MachineStatus.Completed]: { text: 'Complete', backgroundColor: confirmColor },
  [MachineStatus.Queued]: { text: 'Pending', backgroundColor: bodySecondaryColor },
  [MachineStatus.Started]: { text: 'Started', backgroundColor: darkBlue, color: 'red' },
} as Record<string, { text: string; backgroundColor: string; color?: string }>

const AutomationQueueStatus = ({ status }: AutomationQueueStatusProps): JSX.Element => {
  const data = status ? statusQueueMap[status] : undefined
  if (data) {
    return (
      <Lozenge data-testid="status" backgroundColor={data.backgroundColor} color={data.color}>
        {data.text}
      </Lozenge>
    )
  }

  return <></>
}

const StatusLozenge = ({ fulfillmentQueueName, rxFillRequestStatus, orderStatus }: StatusLozengeProps): JSX.Element => {
  const showOrderStatus =
    fulfillmentQueueName === FulfillmentQueueName.Packing || fulfillmentQueueName === FulfillmentQueueName.Complete

  if (showOrderStatus && fulfillmentQueueName !== orderStatus) {
    return (
      <Lozenge data-testid="status" backgroundColor={contrastColor} color="#fff">
        {orderStatus}
      </Lozenge>
    )
  }

  if (fulfillmentQueueName === FulfillmentQueueName.Automation && fulfillmentQueueName !== rxFillRequestStatus) {
    return (
      <Lozenge data-testid="status" backgroundColor="#DBF0FF" color="#007AD1">
        {rxFillRequestStatus}
      </Lozenge>
    )
  }

  if (!showOrderStatus && fulfillmentQueueName !== rxFillRequestStatus) {
    return (
      <Lozenge data-testid="status" backgroundColor={contrastColor} color="#fff">
        {rxFillRequestStatus}
      </Lozenge>
    )
  }

  return <></>
}

const StyledLink = styled(Link)`
  grid-column: rxLink;
`

export const StyledFill = styled.ul<FillProps>`
  ${VerticalStackItemStyle}
  p:first-child {
    font-size: 1.136em;
  }
  grid-template-columns: [rxNumber dateStamp patientName name] 1fr [species] 1rem [workStatusIcons user] 48px;
  ${({ workStatus }) => workStatus === FillWorkStatus.overdue && OverdueFill}
  ${({ workStatus }) => workStatus === FillWorkStatus.nearOverdue && NearOverdueFill}
  ${({ inline, order }) =>
    inline && (order && order.status === OrderStatus.Adjudication ? StyledFillInlineClaimGrid : StyledFillInlineGrid)}
  ${({ isMultiOrder }) => isMultiOrder && MultiOrderFill}
`

const GeneralGrillTemplate = `
  [rxNumber] minmax(120px, 10%)
  [dateStamp] 15%
  [claimAge] 7%
`
const StyledFillInline = css`
  font-family: 'Lato', sans-serif;
  padding: 2px 6px;
  margin: 2px auto 3px;
  height: 2.5rem;
  display: grid;
  align-items: center;
`

const StyledFillInlineClaimGrid = css<FillProps | OrderProps>`
  ${StyledFillInline}
  grid-template-columns:
    ${GeneralGrillTemplate}
    ${({ order }) => (order && !shouldShowNdcAndQuantity(order) ? '[claimRejections] 23%' : '')}
    [species] 2rem
    [patientName] 15%
    ${({ order }) => (order && !shouldShowNdcAndQuantity(order) ? '[name] 10%' : '[name] 18% [drugQuantity] 15%')}
    [statuses] minmax(auto, 20%);
`

const StyledFillInlineGrid = css<FillProps | OrderProps>`
  ${StyledFillInline}
  grid-template-columns:
    ${GeneralGrillTemplate}
    [species] 2rem
    [patientName] 18%
    [name] 20%
    ${({ order }) =>
    order && !shouldShowNdcAndQuantity(order)
      ? '[statuses] minmax(auto, 34%)'
      : '[drugQuantity] 11% [statuses] minmax(auto, 16%)'};
`

export const StyledPriorAuthorization = styled.ul<any>`
  ${VerticalStackItemStyle}
  ${StyledFillInline}
  p:first-child {
    font-size: 1.136em;
  }
  grid-template-columns: [rxNumber] minmax(120px, 10%) [dateStamp] minmax(15%, 22%) [paDateStamp] minmax(15%, 23%) [patientName] 15% [name] 10% [statuses] minmax(
      auto,
      20%
    );
`

export const StyledOTCOrder = styled.ul<OrderProps>`
  ${VerticalStackItemStyle}
  p:first-child {
    font-size: 1.136em;
  }
  ${({ inline }) => inline && StyledFillInlineGrid}
`

const FillSelectedCheckbox = ({
  fill,
  order,
  disabled,
}: {
  fill: Fill
  order: Order
  disabled: boolean
}): JSX.Element => {
  const { select, deSelect, isSelection } = useSelectionContext()
  const {
    tokenContext: { isLeadPharmacist, isPharmacist, isTechnician },
  } = usePlusClient()

  return (
    <StyledCheckboxPadding>
      <CheckBox
        disabled={(fill && !!fill.labelsPrinted && !isLeadPharmacist() && !isPharmacist && !isTechnician) || disabled}
        checked={isSelection(fill)}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const { checked } = e.target
          if (checked) {
            select({ _id: fill._id, fill, order } as Selectable)
          } else {
            deSelect(fill)
          }
        }}
      />
    </StyledCheckboxPadding>
  )
}

const StatusIcons = ({ machine, status }: { machine: MachineOptions; status: RxFillRequestStatus }): JSX.Element => (
  <StatusIconsContainer>
    <RobotTile machine={status === RxFillRequestStatus.Automation && machine?.status !== MachineStatus.Queued} />
  </StatusIconsContainer>
)

const ScanCheckbox = styled.div<{ completed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: ${({ completed }) => (completed ? '#034d83' : '#FFFFFF')};
  border-radius: 56px;
  margin-right: 10px;
  border: 2px solid #034d83;
`

const EmptyBlock = styled.div`
  width: 24px;
  height: 24px;
`

export const StatusIconsContainer = styled.span`
  &:empty {
    display: none;
  }
  grid-column: workStatusIcons;
  grid-row: 1;
  column-gap: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Statuses = styled.span`
  gap: 0.5rem;
  display: flex;
  grid-column: statuses;
  justify-content: flex-end;
`

export const RXNumber = styled.span<{ fade?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column: rxNumber;
  font-weight: 500;
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

const Species = styled.span<{ fade?: boolean }>`
  grid-column: species;
  ${({ fade }) => fade && Fade}
`

export const DateStamp = styled.span<{ fade?: boolean }>`
  grid-column: dateStamp;
  color: ${bodySecondaryColor};
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const PaDateStamp = styled.span<{ fade?: boolean }>`
  grid-column: paDateStamp;
  color: ${bodySecondaryColor};
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const DateStampWithRefillDate = styled(DateStamp)<{ fade?: boolean }>`
  grid-column: 2 / span 2;
`

export const ClaimAge = styled.span<{ fade?: boolean }>`
  grid-column: claimAge;
  color: ${bodySecondaryColor};
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const ClaimRejections = styled.span<{ fade?: boolean }>`
  grid-column: claimRejections;
  color: ${bodySecondaryColor};
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const StyledRevertPadding = styled(ReverseIcon)`
  margin: 0 0 0 0.6rem;
`

export const PatientName = styled.span<{ fade?: boolean }>`
  grid-column: patientName;
  font-weight: 500;
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const Drug = styled.span<{
  fade?: boolean
  shouldDisplayMultiPetVersion?: boolean
  isRxComingFromOrderPage?: boolean
}>`
  grid-column: ${({ shouldDisplayMultiPetVersion, isRxComingFromOrderPage }) =>
    shouldDisplayMultiPetVersion && isRxComingFromOrderPage ? 'patientName' : 'name'};
  ${EllipsisTruncate}
  ${({ fade }) => fade && Fade}
`

export const MultiOrderFill = css`
  margin-bottom: 3px;
`

export const NearOverdueFill = css`
  border-left-color: ${warningYellow};
`

export const OverdueFill = css`
  border-left-color: ${alertRed};
`

export const Fade = css`
  opacity: 0.3;
`

export const StyledCheckboxPadding = styled.div`
  padding: 0 0.6rem 0 0;
`

export const DrugNdcWrapper = styled.span`
  margin-left: 0.425rem;
`

export const DrugQuantityWrapper = styled.span`
  grid-column: drugQuantity;
`

export const UserBadges = styled.span`
  grid-column: user;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 6px;
`

const PackingSelectedCheckbox = ({ order, disabled }: { order: Order; disabled: boolean }): JSX.Element => {
  const { select, deSelect, isSelection } = useSelectionContext()

  return (
    <StyledCheckboxPadding>
      <CheckBox
        disabled={disabled}
        checked={isSelection(order)}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const { checked } = e.target
          if (checked) {
            select({ _id: order._id, order } as Selectable)
          } else {
            deSelect(order)
          }
        }}
      />
    </StyledCheckboxPadding>
  )
}
