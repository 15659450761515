import { Species } from '@truepill/tpos-types'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { all, props, values } from 'ramda'
import { isFalsy, isTruthy } from 'ramda-adjunct'
import type { DeepPartial } from 'ts-essentials'
import type { Order, Patient } from 'types'
import { validateAddressForm } from './address'
import { validPhoneNumber } from './phoneFormatter'

const createValidator = () => {
  let isValid = true
  const append = (b: boolean) => (isValid = isValid && b)
  return {
    append,
    get validity() {
      return isValid
    },
  }
}

const REQUIRED_PATIENT_FORM_FIELDS = ['firstName', 'lastName', 'gender', 'dob']
export const isPatientFormValid = (patient: DeepPartial<Patient>): boolean => {
  const validator = createValidator()

  // check if the patient exists
  validator.append(!!patient)

  // if we have a home address and that address does *not* consist of all null values
  if (patient.address?.home && !all(isFalsy, values(patient.address.home))) {
    validator.append(validateAddressForm(patient.address.home).isValid)
  }

  // phone numnbers are also optional - check if they exist
  if (patient.contacts?.phone) {
    validator.append(validPhoneNumber(patient.contacts.phone))
  }

  // finally, check the required patient fields
  validator.append(all(isTruthy, props(REQUIRED_PATIENT_FORM_FIELDS, patient)))

  return validator.validity
}

export const isHuman = (patient?: Partial<Patient>) => {
  return !patient?.species || patient?.species === Species.Human
}

export const validatePatientForm = (
  patient: DeepPartial<Patient>,
): { isValid: boolean; errors: Record<string, string> } => {
  if (!patient) {
    return { isValid: false, errors: {} }
  }

  // Patient ID means this is a found patient so we can be sure the form has
  // been completed
  if (patient._id) {
    return { isValid: true, errors: {} }
  }

  const { firstName, lastName, gender, dob, contacts, address } = patient
  const errors: Record<string, string> = { ...validateAddressForm(address?.home, true).errors }
  const requiredFields = {
    firstName,
    lastName,
    gender,
    dob,
    phone: contacts?.phone,
  }

  if (moment(dob).isAfter(moment())) {
    errors.dob = 'Cannot be in the future'
  }

  if ((contacts?.phone?.match(/\d+/g) || []).join('').length < 10) {
    errors.phone = '10 digits minimum'
  }

  Object.entries(requiredFields).forEach(([key, value]) => {
    if (!value) {
      errors[key] = 'Missing field'
    }
  })

  return { isValid: isEmpty(errors), errors }
}

// The function getPatientFromOrder is not useful for multipet orders as there would be different patients or for otc-only using guestPatient
// But it's useful when you want to get info from the first patient
export const getPatientFromOrder = (order: Order | undefined) => {
  // Collect all patients from rxFillRequests
  const patients = order?.rxFillRequests?.map(request => request.patient).filter(Boolean) || []

  // Check if there are multiple unique patients and log error when this is true
  const uniquePatientIds = new Set(patients.map(patient => patient._id))
  if (uniquePatientIds.size > 1) {
    const message = `getPatientFromOrder warning: Multiplepatients found in the order. Total unique patients: ${uniquePatientIds.size}`
    console.error(message)
  }

  // Return the first patient from rxFillRequests, or fallback to order.patient (for OTC-only orders or orders without rxFillRequests)
  return patients[0] || order?.patient
}

/**
 * Get all unique patients from an order, including both top-level patient and
 * patients from rxFillRequests
 */
export const getUniqueOrderPatients = (order?: Order): Patient[] => {
  if (!order) return []

  const allPatients = [order?.patient, ...(order.rxFillRequests?.map(request => request.patient) || [])]

  // Deduplicate patients by ID
  const uniquePatientsRecord = allPatients.reduce((acc, patient) => {
    if (patient) acc[patient._id] = patient
    return acc
  }, {} as Record<string, Patient>)

  return Object.values(uniquePatientsRecord)
}
